import * as React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Landing } from 'pages';
import { Navbar } from 'components';
import TermsAndCondition from 'pages/TermsAndCondition';
import PrivacyAndPolicy from 'pages/Privacy and policy';
import LoadingDialog from 'components/Loader';
import { useAccount, useSwitchChain } from 'wagmi';
import { networkConfig } from 'config';

const DApp: React.ComponentType = () => {
	const { isConnected, chain } = useAccount();
	const { switchChain } = useSwitchChain();

	React.useEffect(() => {
		if (isConnected && chain === undefined) {
			switchChain({ chainId: networkConfig.Base.chainId });
		}
	}, [chain, isConnected, switchChain]);

	return (
		<Router>
			<div>
				<LoadingDialog />
				<>
					<Navbar />
					<Switch>
						<Route path="/terms-and-conditions">
							<TermsAndCondition />
						</Route>
						<Route path="/privacy">
							<PrivacyAndPolicy />
						</Route>
						<Route path="/">
							<Landing />
						</Route>
					</Switch>
				</>
			</div>
		</Router>
	);
};

export default DApp;
