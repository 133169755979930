import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SmallItemCard } from 'pages/NewDashboard/components/SmallItemCard';
import { Box, Button, Typography } from '@mui/material';
import { AmountInputBox } from 'pages/Landing/components/AmountInputBox';
import styled from '@emotion/styled';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import { getUserDeposit, userDeposit } from 'apis';
import { hasOneHourPassed, hasXMinutesPassed } from 'utils/lockDuration';
import moment from 'moment/moment';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';

const Row = styled('div')`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
`;

const Text = styled(Typography)`
	font-weight: 500;

	:hover {
		color: #235ee1;
		cursor: pointer;
	}
`;

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledButton = styled(Button)`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;

	:hover {
		box-shadow: 0 4px 4px transparent;
	}
`;

export const WithdrawPanel = () => {
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');
	const { address: account, isConnected } = useAccount();
	const dispatch = useDispatch();
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.value === appVault.exchange,
	);
	const signer: any = useEthersSigner();
	const bal = parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();
	const contractBalance = parseFloat(
		parseFloat(contractBal).toFixed(2),
	).toLocaleString();
	const isAccountConnected = isConnected;
	const payload = useMemo(() => {
		return {
			contract: selectedContract?.contractAddress || '',
			address: account || '',
		};
	}, [account, selectedContract?.contractAddress]);

	useNetwork();

	const buttonLabel = useMemo(() => {
		if (!isAccountConnected) return 'Connect Wallet';

		if (loading) return 'Please wait...';

		if (lockedTime && hasXMinutesPassed(lockedTime || '', 10)) {
			return `Unlock at ${moment(lockedTime)
				.add(10, 'minutes')
				.format('DD-MMM, hh:mm A')}`;
		}

		return 'Withdraw';
	}, [isAccountConnected, loading, lockedTime]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [account, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const userContractBalance = await contract.balanceOf(account);
				const userBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userBal, selectedAsset?.decimalValue) || '0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		selectedContract?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
		dispatch,
	]);

	const withdraw = async () => {
		try {
			setLoading(true);

			const res = await getUserDeposit(payload);
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
				if (!hasOneHourPassed(res.lastDeposit[0].createdAt)) {
					const contract = getDepositContract(
						selectedContract?.contractAddress || '',
						signer?.provider.getSigner(account).connectUnchecked(),
					);

					const tx = await contract.withdraw(
						parseUnits(amount, selectedAsset?.decimalValue),
						account,
						account,
					);
					await tx.wait();
					await userDeposit({
						contract: selectedContract?.contractAddress || '',
						address: account || '',
						amount,
						token: 'USDC',
						eventType: 'withdraw',
					});
					toast('Successfully withdrawn from vault contract', {
						type: 'success',
					});
					setAmount('0');

					return;
				}
			}
			toast('Sorry your amount is locked for 1h', {
				type: 'error',
			});
		} catch (e: any) {
			toast(
				getErrorMessage(e?.error?.code || e?.code, e) ||
					'Something went wrong',
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			setLoading(false);
		}
	};

	const onPercentClick = (txt: number) => {
		let balanceNeeded = contractBal;
		if (balanceNeeded.includes(',')) {
			balanceNeeded = balanceNeeded.replace(/,/g, '');
		}
		setAmount(((parseFloat(balanceNeeded) * txt) / 100).toString());
	};

	const fetchLockedTime = useCallback(async () => {
		const res = await getUserDeposit(payload);
		if (res.lastDeposit.length > 0) {
			setLockedTime(res.lastDeposit[0].createdAt);
		}
	}, [payload]);

	useEffect(() => {
		fetchContractBalance();
		fetchBalance();
		fetchLockedTime();
	}, [fetchBalance, fetchContractBalance, fetchLockedTime]);

	return (
		<SpaceBetween>
			<Column>
				<SmallItemCard />
				<Box height={16} />
				<AmountInputBox
					currency={{
						icon: selectedVault.token[0].icon,
						name: selectedVault.token[0].name,
					}}
					text={amount}
					onChange={setAmount}
					leftTxt={`${selectedVault.currency} ${bal}`}
					rightTxt={selectedVault.currency + ' ' + contractBalance}
				/>
				<Box height={10} />
				<Row>
					<Text onClick={() => onPercentClick(0)}>0%</Text>
					<Text onClick={() => onPercentClick(25)}>25%</Text>
					<Text onClick={() => onPercentClick(50)}>50%</Text>
					<Text onClick={() => onPercentClick(100)}>100%</Text>
				</Row>
				<Box height={16} />
				<hr />
				<Box height={5} />
			</Column>
			<Column>
				<StyledButton
					variant={'contained'}
					onClick={() => {
						withdraw();
					}}
					disabled={loading || parseFloat(amount || '0') === 0}
				>
					<Typography fontWeight={600} color={'#fff'}>
						{buttonLabel}
					</Typography>
				</StyledButton>
				<Box height={32} />
			</Column>
		</SpaceBetween>
	);
};
